/**
 * Created by fulle on 2024/02/26.
 */
import http from "@/http-common";

export default {
    state: {
    },
    mutations: {
    },
    actions: {

        REQUEST_APPLY_SELF_EXCLUSION: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: `apply/self-exclusion`,
                    data: _formData,
                    method: "POST",
                    headers:{
                        'Content-Type': 'multipart/form-data',
                        'enctype': 'multipart/form-data',
                    },
                    timeout: 30000
                })
                    .then(_response => {
                        window.console.log("Response SELF EXCLUSION APPLICATION: ", _response);
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        REQUEST_APPLY_THIRD_PARTY_EXCLUSION: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: `apply/third-party-exclusion`,
                    data: _formData,
                    method: "POST",
                    headers:{
                        'Content-Type': 'multipart/form-data',
                        'enctype': 'multipart/form-data',
                    },
                    timeout: 30000
                })
                    .then(_response => {
                        window.console.log("Response THIRD PARTY EXCLUSION APPLICATION: ", _response);
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        REQUEST_APPLY_APPEAL_EXCLUSION: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: `apply/appeal-exclusion`,
                    data: _formData,
                    method: "POST",
                    headers:{
                        'Content-Type': 'multipart/form-data',
                        'enctype': 'multipart/form-data',
                    },
                    timeout: 30000
                })
                    .then(_response => {
                        window.console.log("Response THIRD PARTY EXCLUSION APPLICATION: ", _response);
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        REQUEST_APPLY_CANCEL_EXCLUSION: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: `apply/request-cancel-exclusion`,
                    data: _formData,
                    method: "POST",
                    headers:{
                        'Content-Type': 'multipart/form-data',
                        'enctype': 'multipart/form-data',
                    },
                    timeout: 30000
                })
                    .then(_response => {
                        window.console.log("Response CANCEL EXCLUSION APPLICATION: ", _response);
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },


        REQUEST_LODGE_COMPLAINT: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: `help/lodge-complaint`,
                    data: _formData,
                    method: "POST",
                    timeout: 30000
                })
                    .then(_response => {
                        window.console.log("Response REQUEST_LODGE_COMPLAINT: ", _response);
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        REQUEST_REQUEST_COUNSELLING: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: `help/request-counselling`,
                    data: _formData,
                    method: "POST",
                    timeout: 30000
                })
                    .then(_response => {
                        window.console.log("Response REQUEST_COUNSELLING: ", _response);
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        REQUEST_REPORT_ILLEGAL_GAMBLING: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: `help/report-illegal-gambling`,
                    data: _formData,
                    method: "POST",
                    timeout: 30000
                })
                    .then(_response => {
                        window.console.log("Response REQUEST_REPORT_ILLEGAL_GAMBLING: ", _response);
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        REQUEST_TALK_TO_OFFICER: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });

            return new Promise(resolve => {
                http({
                    url: `contact/talk-to-officer`,
                    data: _formData,
                    method: "POST",
                    timeout: 30000
                })
                    .then(_response => {
                        window.console.log("Response REQUEST_TALK_TO_OFFICER: ", _response);
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        REQUEST_SAVE_ASSESSMENT: ({ dispatch }, _formData) => {

            return new Promise(resolve => {
                http({
                    url: `assessment`,
                    data: _formData,
                    method: "POST",
                    timeout: 30000
                })
                    .then(_response => {
                        window.console.log("Response REQUEST_SAVE_ASSESSMENT: ", _response);
                        resolve(_response);
                    })
                    .catch(_response => {
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
    },
};