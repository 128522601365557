<!-- 
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->

<template>
	<div id="app">
		<loading-component></loading-component>
		<confirm-modal-component :actionName="actionName"></confirm-modal-component>
		<message-modal-component :messages="messages"></message-modal-component>
		<component :is="layout">
			<router-view />
		</component>
	</div>
</template>

<script>

    import {mapState} from "vuex";
    import ConfirmModalComponent from "./app/components/ant-design/ConfirmModalComponent";
    import MessageModalComponent from "./app/components/ant-design/MessageModalComponent";
    import LoadingComponent from "./app/components/ant-design/LoadingComponent";

    export default ({
        components: {
            ConfirmModalComponent,
            MessageModalComponent,
            LoadingComponent,
        },
		computed: {
            ...mapState({
                messages: state => state.messages,
                actionName: state => state.actionName,

            }),
			// Sets components name based on current route's specified layout, defaults to
			// <layout-default></layout-default> component.
			layout() {
				return "layout-" + ( this.$route.meta.layout || "default" ).toLowerCase() ;
			}
		},
	})
	
</script>

<style>
	@import "assets/margin.css";
	@import "assets/style.css";
	@import "assets/padding.css";
	@import "assets/borders.css";
	@import "assets/loader.css";
	@import "assets/vert-offset.css";
	@import "assets/colors.css";
	@import "assets/text.css";

</style>