<template>
  <div id="myMessageModal">
    <a-modal v-model="visible" title="Message(s)" @ok="handleOk">
      <template #footer>
        <a-button key="submit" type="primary" @click.prevent="handleOk">{{ $t('ok')}}</a-button>
      </template>
      <p v-for="(opt, index) in messages" :key="index">{{ opt }}</p>
    </a-modal>
  </div>
</template>

<script>

export default {
  name: "message-modal-component",
  props: {
    messages: {
      type: Array
    }
  },


  data() {
    return {
      visible: false
    };
  },

  methods: {

      showModal() {
          this.visible = true;
      },
      closeModal()
      {
          this.visible = false;
      },
      handleOk() {
          this.closeModal();
      }
  },
  mounted() {

  },
  watch: {
    messages() {
      if(this.hasMessages)
      {
        this.showModal();
      }
    }
  },
  computed: {
      hasMessages()
      {
          return typeof this.messages !== "undefined" && this.messages !== null && this.messages.length > 0;
      }
  },
  destroyed() {
    //this.$el.removeEventListener('change', this.changeHandler)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
