// VueJS
import Vue from 'vue'

// Ant Design Vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.use(Antd);

/*import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import en from './app/translations/en.json';
import fr from './app/translations/fr.json';
import ts from './app/translations/fr.json';*/

// Photoswipe Gallery
import Photoswipe from 'vue-pswipe'
Vue.use(Photoswipe);

// Template Layouts
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

// Main application view
import App from './App.vue'

// Vue Router
import router from './router'
import store from "./store";

// App Styling
import './scss/app.scss';

//Vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

Vue.config.productionTip = false;

/*const messages = {
    en: en,
    fr: fr,
    ts: ts
};

const i18n = new VueI18n({
    locale: 'en',
    messages
});*/

import i18n from "@/app/i18n";

// Initialize Vue
new Vue({
  router,
    store,
  render: h => h(App),
    i18n
}).$mount('#app')