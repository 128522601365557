<template>
	
	<!-- Layout Footer -->
	<a-layout-footer class="white ga-gradient-1">
		<a-row type="flex">
			<a-col :span="24" :md="12">

				<!-- Footer Copyright Notice -->
				<p class="copyright">
					© {{new Date().getFullYear()}}
					<a href="https://gamblingauthority.co.bw/" target="_blank">Botswana Gambling Authority</a>. All rights Reserved<br/>
					<span class="font-10">Powered by <a href="https://brandboxbw.com" target="_blank">BrandBox Botswana</a></span>
				</p>
				<!-- / Footer Copyright Notice -->

			</a-col>
			<a-col :span="24" :md="12" class="footer-control">

				<!-- Footer Navigation Menu -->
				<a-menu mode="horizontal">
					<a-menu-item>
						<span>{{$t('title_toll_free_number')}}<span class="margin-left-half font-bold text-ga-blue-3"><a-icon type="phone-fill"/>{{$t('toll_free_number')}}</span></span>
					</a-menu-item>
					<a-menu-item><a href="https://www.facebook.com/gamblingauthoritybw" target="_blank">
						<a-icon type="facebook" class="font-18"></a-icon>
					</a></a-menu-item>
					<a-menu-item><a href="https://www.twitter.com/gamblingbw" target="_blank">
						<a-icon type="twitter" class="font-18"></a-icon>
					</a></a-menu-item>
					<a-menu-item><a href="http://linkedin.com/gamblingauthority" target="_blank">
						<a-icon type="linkedin" class="font-18"></a-icon>
					</a></a-menu-item>
				</a-menu>
				<!-- / Footer Navigation Menu -->

			</a-col>
		</a-row>
	</a-layout-footer>
	<!-- / Layout Footer -->

</template>

<script>

	export default ({
		data() {
			return {
			}
		},
	})

</script>
