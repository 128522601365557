/**
 * Created by fulle on 2024/02/29.
 */

import Vue from 'vue'
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import en from '@/app/translations/en.json';
import fr from '@/app/translations/fr.json';
import ts from '@/app/translations/ts.json';
import kga from '@/app/translations/kga.json';
import kal from '@/app/translations/kal.json';

const messages = {
    en: en,
    fr: fr,
    ts: ts,
    kga: kga,
    kal: kal,
};

const i18n = new VueI18n({
    locale: 'en',
    messages
});

export default i18n;