/**
 * Created by fulle on 2023/03/01.
 */
import moment from "moment"

const isValidDate = (value) => {
    if(typeof value !== "undefined" && value !== null && value.length > 0)
        return moment(value).isValid()

    return true
}

const isValidOmang = (value) => {
    if(typeof value !== "undefined" && value !== null && value.length === 9)
    {
        return (["1","2"].indexOf(value[4].toString()) >= 0)
    }

    return false
}

const itemExists = (_value) => {
    return typeof _value !== "undefined" && _value !== null
}

const itemListExists = (_value) => {
    return typeof _value !== "undefined" && _value !== null && _value.length > 0
}

const textExists = (_value) => {
    return typeof _value !== "undefined" && _value !== null && _value.length > 0
}

const getGenderFromOmang = (value) => {
    if(typeof value !== "undefined" && value !== null && value.length === 9)
    {
        if(value[4].toString() == "1")
            return "Male"
        else if(value[4].toString() == "2")
            return "Female"

    }

    return null
}

const isNumeric = (_value) => {
    return !isNaN(parseFloat(_value)) && isFinite(_value);
}

const getStatusBGColor = (_status) => {
    let _color = ""
    if(itemExists(_status))
    {
        if(_status.toUpperCase() === "INITIATED")
            _color = "orange lighten-1"
        else if(_status.toUpperCase() === "CONFIRMED")
            _color = "green accent-1"
        else if(_status.toUpperCase() === "REJECTED")
            _color = "red accent-2"
        else if(["COMPLETE", "COMPLETED"].indexOf(_status.toUpperCase()) >= 0)
            _color = "deep-purple accent-2"
        else if(["SUBMITTED"].indexOf(_status.toUpperCase()) >= 0)
            _color = "blue lighten-5"
        else if(["REFUNDABLE"].indexOf(_status.toUpperCase()) >= 0)
            _color = "cyan lighten-3"
        else if(["REFUNDED"].indexOf(_status.toUpperCase()) >= 0)
            _color = "cyan accent-4"
        else if(["EXTENDABLE"].indexOf(_status.toUpperCase()) >= 0)
            _color = "lime lighten-2"
        else if(["EXTENDED"].indexOf(_status.toUpperCase()) >= 0)
            _color = "lime accent-4"
        else if(["SUBMITTED"].indexOf(_status.toUpperCase()) >= 0)
            _color = "blue lighten-5"
    }
    return _color
}

const isValidContactNumber = (_value) => {
    return /^\+\d{6,15}$/.test(_value)
}

export { isValidDate, isValidOmang, getGenderFromOmang, itemExists, textExists, itemListExists, getStatusBGColor, isNumeric }